<template>
	<div>
		<h1>Pesquisar CTe</h1>

		<v-row>
			<v-col cols="12" md="4">
				<v-form @submit.prevent="search('cte')">
					<v-text-field
						label="Pesquisa CTe"
						append-icon="mdi-file-search"
						@click:append="search('cte')"
						v-model="cte"
					></v-text-field>
				</v-form>
			</v-col>
			<v-col cols="12" md="4">
				<v-form @submit.prevent="search('nf')">
					<v-text-field
						label="Pesquisa NF"
						append-icon="mdi-file-search"
						@click:append="search('nf')"
						v-model="nf"
						autofocus
					></v-text-field>
				</v-form>
			</v-col>
			<v-col cols="12" md="4">
				<v-form @submit.prevent="search('customer')">
					<v-text-field
						label="Pesquisa Cliente"
						append-icon="mdi-account-search"
						@click:append="search('customer')"
						v-model="cnpj"
					></v-text-field>
				</v-form>
			</v-col>
		</v-row>
		<v-data-table
			:items="ctes"
			:headers="headers"
			dense
			:loading="loading"
			@click:row="$router.push(`/editar-cte/${$event.id}`)"
		></v-data-table>
	</div>
</template>

<script>
import http from '@/modules/http'
export default {
	data() {
		return {
			loading: false,
			cte: '',
			nf: '',
			cnpj: '',
			ctes: [],
			headers: [
				{text: 'ID', value: 'id'},
				{text: 'CTe', value: 'number'},
				{text: 'NF', value: 'nf'},
				{text: 'Remetente', value: 'senders.name'},
				{text: 'Destinatário', value: 'recipients.name'},
			]
		}
	},
	methods: {
		search(param) {
			this.loading = true
			if(param == 'customer') {
				http.get('api/ctes-filter?customer='+this.cnpj)
					.then(res => {
						this.loading = false
						this.ctes = res.data
					})
			} else if(param == 'cte') {
				http.get('api/ctes-filter?cte='+this.cte)
					.then(res => {
						this.loading = false
						this.ctes = res.data
					})
			} else if(param == 'nf') {
				http.get('api/ctes-filter?nf='+this.nf)
					.then(res => {
						this.loading = false
						this.ctes = res.data
					})
			} else {
				this.loading = false
				this.ctes = []
			}
		}
	}
}
</script>